<template>
  <div>
    <v-dialog persistent :value="dialogSync" max-width="600" class="rounded-10">
      <v-card>
        <div class="d-flex flex-column align-center text-center pa-8">
          <div class="mb-5">
            <hollow-dots-spinner
              :animation-duration="1000"
              :dot-size="20"
              :dots-num="3"
              color="#099845"
            />
          </div>
          <p class="font-weight-bold">Sincronizando os dados...</p>
          <p class="caption">
            Esse processo pode levar alguns minutos, aguarde.
          </p>
          <p class="caption font-weight-bold orange--text text--darken-2">
            Favor não atualizar a página.
          </p>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HollowDotsSpinner } from "epic-spinners";
export default {
  components: {
    "hollow-dots-spinner": HollowDotsSpinner,
  },
  computed: {
    dialogSync() {
      return this.$store.state.sync.dialog;
    },
  },
};
</script>

<style></style>
